.liveStormRow {
    display: flex;
    align-items: center;
    justify-content: center;
    margin: 0 0vw;
    gap: 0.8vw;
    font-size: 1.6vh;
}

.warning {
    animation: fadeIn 1s ease-in-out forwards;
    color: darkorange;
    margin-top: 1vh;
    text-align: center;
    font-size: smaller;
    flex-grow: 1;
    justify-content: center;
}

.resultLabel {
    font-weight: bold;
    width: 9vw;
}

.resultsWrapper {
    animation: fadeIn 1s ease-in-out forwards;
    display: flex;
    width: auto;
    flex-grow: 1;
    justify-content: space-between;
    align-items: flex-start;
}

.fixedSizeContainer {
    display: grid;
    grid-template-columns: repeat(4, auto); 
    gap: 0.5vw; 
    width: 18vw; 
    align-items: center; 
}

.fixedSizeContainer > div {
    display: flex;
    align-items: center;
    font-family: monospace; 
}

.fixedSizeLabel {
    width: 1vw; 
}

.additionalInfoContainer {
    display: flex;
    flex-direction: row;
    flex-grow: 1;
    width: 18vw; 
    gap: 0.5vw;

}

.hidden {
    animation: neverFadeIn 1s ease-in-out forwards;
}

.loading {
    animation: infiniteFadeIn 2s ease-in-out infinite;
    display: flex;
    width: 100%;
    flex-grow: 1;
    justify-content: center;
    color: rgb(255, 0, 0);
}

.alert {
    animation: fadeIn 1s ease-in-out forwards;
    justify-content: center;
    color: red;
    text-align: center;
    flex-grow: 1;
}

@keyframes fadeIn {
    from {
        opacity: 0;
    }

    to {
        opacity: 1; 
    }
}

@keyframes infiniteFadeIn {
    0% {
        opacity: 0;
    }

    50% {
        opacity: 1; 
    }

    100% {
        opacity: 0;
    }
}

@keyframes neverFadeIn {
    from {
        opacity: 0;
    }

    to {
        opacity: 0;
    }
}
